body {
  margin: 0;
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;

  font-family: 'Cairo', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #161e28;
  background-position: center;
  background-size: auto 175vh;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-image: url('assets/Group.svg');
  color: white;

  display: flex;
  align-items: center;
  justify-content: center;
}

main {
  display: flex;
  flex-direction: column;
}

h3 {
  font-size: 1em;
  margin-top: 0em;
  margin-bottom: 0.5em;
  text-transform: uppercase;
  color: #828282;
  line-height: 1.25em;
}

h1 {
  font-weight: normal;
  font-size: 3em;
  margin-top: 0em;
  margin-bottom: 0.5em;
  line-height: 1.25em;
}

form {
  display: flex;
  flex-direction: column;
  max-width: 360px;
}

form label {
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 0.5em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#call-frame-container {
  width: 100vw;
  height: 100vh;
}

.recording {
  display: none;
}

form, input, button {
  border-radius: 4px;
  color: white;
  font-family: 'Cairo', sans-serif;
}


button {
  background-color: #2b2bfa;
  border: none;
  margin-bottom: 1em;
  padding: 5px;
  font-weight: bold;
  font-size: 1em;
}

button:hover:enabled {
  background-color: #3b3bff;
}

button:disabled {
  background-color: #999;
}

input {
  background-color: #0a121b;
  margin-bottom: 2em;
  padding: 5px 15px;
  border: 1px solid #aaa;
}

#logo {
  position: fixed;
  top: 1.5em;
  left: 1.5em;
  z-index: -999;
  height: 2em;
}

.disclaimer {
  font-size: 0.75em;
  text-align: center;
  margin-top: 1.5em;
}


.secondaryButton {
  background-color: transparent;
  border: 3px solid #aaa;
  color: #aaa;
}

.secondaryButton:hover:enabled {
  background-color: transparent;
  border: 3px solid #ccc;
  color: #ccc;
}

.secondaryButton:disabled {
  background-color: transparent;
  border: 3px solid #333;
  color: #333;
}

.small {
  height: 80%;
  width: 80%;
  font-size: 80%;
  margin-left: 10%;
  margin-right: 10%;
}

a {
  text-decoration: none;
  color: #aaf;
}

a:hover {
  text-decoration: underline;
}

.error {
  font-size: 80%;
  color: #fb3640;
  text-align: center;
  width: 100%;
}